import { fetchCustomization } from "./customization";

/**
 * Asserts the browser is Internet Explorer.
 */
export const isIE = () => {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf("MSIE ");

    return msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./);
}

/**
 * Injects the IE compatible loader script file.
 */
export const injectIECompatibleLoader = async () => {
    const currentLoaderTag = document.getElementById('le-petit-martin');
    const agentId = currentLoaderTag.getAttribute('data-agent-id');
    const compatibleLoaderTag = document.createElement('script');

    const customization = await fetchCustomization(agentId);

    compatibleLoaderTag.type = 'text/javascript';
    compatibleLoaderTag.id = 'le-petit-martin';
    compatibleLoaderTag.dataset.agentId = agentId;
    compatibleLoaderTag.dataset.color = customization.color;
    compatibleLoaderTag.dataset.avatar = customization.avatar_url;
    compatibleLoaderTag.dataset.logo = customization.logo_url;
    compatibleLoaderTag.dataset.zindex = customization.z_index;
    compatibleLoaderTag.src = 'https://static.lepetitmartin.com/iframes/v2-legacy/loader/index.js';
    compatibleLoaderTag.dataset.welcomeMessage = customization.greeting_messages.join(' ');

    if (customization.background_url) {
        compatibleLoaderTag.dataset.background = customization.background_url;
    }

    if (customization.default_open) {
        compatibleLoaderTag.dataset.open = customization.default_open;
    }
    
    document.getElementsByTagName('head')[0].appendChild(compatibleLoaderTag);
    currentLoaderTag.remove();
}
