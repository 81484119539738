import {
  EVENTS,
  onMessage,
  sendMessage,
  MessagePayload,
} from '../common/messaging';
import { disableScroll, reenableScroll } from './behaviors/disable-scroll';
import { disableZoom, reenableZoom } from './behaviors/disable-zoom';
import { isMobile } from '../common/media-queries';
import { shouldDisplayBubble } from '../common/utils';

interface IIframes {
  button: HTMLIFrameElement;
  bubble: HTMLIFrameElement;
  widget: HTMLIFrameElement;
}

/**
 * Dispatch a given event to all iframes.
 * @param iframes
 * @param payload
 */
const dispatchEventToAllIframes = (
  iframes: IIframes,
  payload: MessagePayload
) => {
  Object.values(iframes).map((iframe: HTMLIFrameElement) =>
    sendMessage(payload, iframe)
  );
};

/**
 * Handles the open event generated by the click on the iframe button.
 * @param widget
 */
const handleWidgetOpen = (button: HTMLIFrameElement, widget: HTMLIFrameElement) => {
  button.classList.add('ButtonIframe--hidden');
  widget.classList.add('ChatboxIframe--open');
  disableScroll();
  disableZoom();
};

/**
 * Handles the close event generated by the click on the iframe button.
 * @param widget
 */
const handleClose = (button: HTMLIFrameElement, widget: HTMLIFrameElement) => {
  button.classList.remove('ButtonIframe--hidden');
  widget.classList.remove('ChatboxIframe--open');
  reenableScroll();
  reenableZoom();
};  

/**
 * Handle the widget ready event.
 * @param widget
 */
const handleWidgetReady = (widget: HTMLIFrameElement) => {
  sendMessage(
    {
      event: isMobile() ? EVENTS.IS_MOBILE : EVENTS.IS_NOT_MOBILE,
    },
    widget
  );
};

const showBubble = (bubble: HTMLIFrameElement) =>
  bubble.classList.add('BubbleIframe--toggled');

/**
 * Deletes the bubble iframe if exists.
 */
const hideBubble = (bubble: HTMLIFrameElement) => {
  bubble.classList.remove('BubbleIframe--toggled');
  sessionStorage.setItem('lpb_bubble', 'disabled');
};

/**
 * Asserts the widget is open
 */
const isWidgetOpen = (payload: any) =>
  payload.attributes.customization.default_open && !payload.attributes.fromBo;

/**
 * Builds a LPB event with the given payload.
 * @param payload
 */
const buildLpbEvent = (payload: object) =>
  new CustomEvent('__lpb__', { detail: payload });

/**
 * Whenever a message is received from an iframe or the loader, retransmits it to all the
 * iframes. It acts kind of like a message bus.
 * @param iframes
 */
const handleEventsFromIframes = (iframes: IIframes) => {
  const rootTag = document.getElementById('le-petit-martin');

  onMessage((payload: MessagePayload) => {
    dispatchEventToAllIframes(iframes, payload);

    switch (payload.event) {
      case EVENTS.WIDGET_READY:
        return handleWidgetReady(iframes.widget);

      case EVENTS.READY_TO_BUILD:
        isWidgetOpen(payload) && handleWidgetOpen(iframes.button, iframes.widget);
        return shouldDisplayBubble(payload) && showBubble(iframes.bubble);

      case EVENTS.BUBBLE_TOGGLED_OFF:
        hideBubble(iframes.bubble);
        return;
      case EVENTS.BUTTON_TOGGLED_ON:
      case EVENTS.BUBBLE_CLICKED:
        const openWidgetEvent = buildLpbEvent({ type: 'open widget' });

        hideBubble(iframes.bubble);
        rootTag.dispatchEvent(openWidgetEvent);
        return handleWidgetOpen(iframes.button, iframes.widget);

      case EVENTS.CHATBOX_CLOSE:
        const closeWidgetEvent = buildLpbEvent({ type: 'close widget' });

        rootTag.dispatchEvent(closeWidgetEvent);
        return handleClose(iframes.button, iframes.widget);
    }
  });
};

export default handleEventsFromIframes;
