export const SCREENS = {
  sm: { min: '576px', max: '575.98px' },
  md: { min: '768px', max: '767.98px' },
  lg: { min: '992px', max: '991.98px' },
  xl: { min: '1200px', max: '1199.98px' },
};

export function isMobile() {
  return window.matchMedia(`(max-width: ${SCREENS.sm.max})`).matches;
}
