const TARGET = process.env.TARGET;
const VERSION = 'v2';
// const VERSION = 'v2.1-beta';

module.exports = {
  TARGET,
  VERSION,

  // The API endpoint that will be used to talk with the chatbot
  API_ENDPOINT: (() => {
    switch (TARGET) {
      case 'production':
        return 'https://lepetitmartin.com/api';

      case 'staging':
        return 'https://staging.lepetitmartin.com/api';

      case 'development':
        return 'https://lepetitmartin.test/api';
    }
  })(),

  LEGACY_API_ENDPOINT: (() => {
    switch (TARGET) {
      case 'production':
        return 'https://api.lepetitmartin.com';

      case 'staging':
        return 'https://api.staging.lepetitmartin.com';

      case 'development':
        return 'http://localhost:8040';
    }
  })(),

  // The base URL on which the loader/button/widget bundles are served
  BASE_URL:
    TARGET === 'production'
      ? `https://static.lepetitmartin.com/iframes/${VERSION}`
      : `https://static.lepetitmartin.com/iframes/${VERSION}-${TARGET}`,
};
