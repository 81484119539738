/* prettier-ignore */

/**
 * Configuration keys will default to their production values if not set.
 * Feel free to comment-out the one you don't need.
 */

module.exports = {
  // The API endpoint that will be used to talk with the chatbot
  API_ENDPOINT: 'https://lepetitmartin.test/api',

  LEGACY_API_ENDPOINT: 'http://localhost:8040',

  // The base URL on which the loader/button/widget bundles are served
  BASE_URL: '',
};