// In order to disable zoom, we have to replace any `<meta name="viewport">` by
// a custom one. When reenabling zoom, we have to restore the previous meta tag,
// hence the need for backing it up.
let savedViewport: any = null;

export function disableZoom() {
  // Backup any pre-existing <meta name="viewport"> tag
  savedViewport = document.querySelector('meta[name=viewport]');

  // Remove it from the DOM, so that we can replace it
  if (savedViewport) {
    savedViewport.remove();
  }

  // Add the zoom-disabling meta tag to the page
  const meta = createZoomDisablingMeta();
  document.head.appendChild(meta);
}

export function reenableZoom() {
  const zoomLockViewport = document.getElementById('lepetitmartin-viewport');

  // Remove the zoom-disabling meta tag from the page
  if (zoomLockViewport) {
    zoomLockViewport.remove();
  }

  // Restore the previous meta tag
  if (savedViewport) {
    document.head.appendChild(savedViewport);
  }
}

function createZoomDisablingMeta() {
  const meta = document.createElement('meta');

  meta.name = 'viewport';
  meta.id = 'lepetitmartin-viewport';
  meta.content =
    'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0';

  return meta;
}
