import { SCREENS } from '../common/media-queries';

/**
 * Because we want to generate a single file, and a small bundle. We'll
 * manually do what a CSS-in-JS package would do. That's why we manually
 * inject CSS inside the page.
 */
const css = `
  .ButtonIframe,
  .ChatboxIframe,
  .BubbleIframe {
    border: 0;
    z-index: 1000;
    user-select: none;
  }

  .ButtonIframe {
    position: fixed;
    bottom: 20px;
    right: 20px;
    height: 68px !important;
    min-height: 68px !important;
    max-height: 68px !important;
    width: 68px !important;
    min-width: 68px !important;
    max-width: 68px !important;
  }

  .ButtonIframe--hidden {
    display: none;
  }

  .ChatboxIframe {
    opacity: 0;
    position: fixed;
    height: 100%;
    width: 100%;
    right: 100%;
    bottom: 0;
    transition: opacity 0.2s;
  }

  .ChatboxIframe--open {
    opacity: 1;
    right: 0;
  }

  .BubbleIframe {
    position: fixed;
    height: 0;
    width: 0;
  }

  .BubbleIframe--toggled {
    height: 200px;
    width: 325px;
    bottom: 100px;
    right: 16px;
  }

  @media (min-width: ${SCREENS.sm.min}) {
    .ChatboxIframe {
      opacity: 0;
      transition: opacity 0.2s ease;
      height: 0px;
      width: 0px;
    }

    .ChatboxIframe--open {
      opacity: 1;
      min-height: 350px;
      max-height: 600px;
      height: 70vh;
      width: 410px;
      right: 24px;
      bottom: 24px;
      border-radius: 24px;
      box-shadow: 0 5px 30px rgba(0, 0, 0, 0.24);
      transition: opacity 0.2s ease;
    }

    .BubbleIframe {
      width: 370px;
    }
  }

  @media (max-width: ${SCREENS.sm.max}) {
    html.lepetitmartin-widget-open,
    html.lepetitmartin-widget-open > body {
      position: static !important;
      overflow: hidden !important;
    }

    html.lepetitmartin-widget-open > body {
      height: 0 !important;
      margin: 0 !important;
    }
  }
`;

const fromBoCss = `
  .ButtonIframe {
    right: 20px !important;
    bottom: 20px !important;
    height: 60px !important;
    width: 60px !important;
  }
`;

/**
 * Injects a stylesheet in the <head> tag.
 * @param {string} css
 */
const injectStyleSheet = (css: string) => {
  // Create a <style> tag
  const style = document.createElement('style');
  style.type = 'text/css';

  style.appendChild(document.createTextNode(css));

  // Add it to the page
  document.head.appendChild(style);
};

/**
 * Injects the common css.
 */
export const injectCommonCss = () => injectStyleSheet(css);

/**
 * Injects the custom css.
 */
export const injectCustomCss = (fromBo: boolean, customCss: string) => {
  fromBo && injectStyleSheet(fromBoCss);
  customCss && injectStyleSheet(customCss);
};
