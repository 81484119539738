import { EVENTS, sendMessage } from './messaging';

const hasSessionStorageActive = () => {
  try {
    window.sessionStorage;

    return true;
  } catch {
    return false;
  }
};

export function getParameterByName(name: string, url = window.location.href) {
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

function bubbleDisabled (useLocalStorageOnClosure: boolean) {
  return useLocalStorageOnClosure ?
    localStorage.getItem('lpb_bubble') === 'disabled' :
    sessionStorage.getItem('lpb_bubble') === 'disabled';
}

export const disableBubble = (useLocalStorageOnClosure: boolean) => {
  useLocalStorageOnClosure ?
    localStorage.setItem('lpb_bubble', 'disabled') :
    sessionStorage.setItem('lpb_bubble', 'disabled');
    sendMessage({
      event: EVENTS.BUBBLE_TOGGLED_OFF,
    });
};

export const shouldDisplayBubble = (payload: any) =>
  !payload.attributes.customization.default_open &&
  payload.attributes.customization.bubble_enabled &&
  !payload.attributes.fromBo &&
  hasSessionStorageActive() &&
  !bubbleDisabled(payload.attributes.customization.use_local_storage_on_closure);
