import config from '../../config';

export function setAvatar(customization: any) {
  const randomAvatars = customization.random_avatars_urls;

  if (randomAvatars) {
    customization.avatar_url =
      randomAvatars[Math.floor(Math.random() * randomAvatars.length)];
  }

  return customization;
}

export const fetchCustomization = async (agentId: string): Promise<any> => {
  const response = await fetch(
    `${config.API_ENDPOINT}/agent/${agentId}/customization-with-url`,
    {
      headers: {
        'content-type': 'application/json',
      },
    }
  );
  let { customization } = await response.json();

  return setAvatar(customization);
};
