import config from '../../../config';

export const talk = async (
  query: string,
  projectId: string,
  fromBo: boolean,
  contextIntentsIds: string[],
  contextInputs: string[],
  isSuggested: boolean = false,
) => {
  const response = await fetch(`${config.API_ENDPOINT}/talk`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      projectId,
      sessionId: getSessionId(),
      fromBo,
      query,
      contextIntentsIds,
      contextInputs,
      isSuggested,
    }),
  });

  return await response.json();
};

export const handleFeedback = async (
  talkId: number,
  feedbackType: string,
) => {
  await fetch(`${config.API_ENDPOINT}/conversation/talk/feedback`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      talkId,
      feedbackType,
    }),
  });
};

export const addComment = async (
  talkId: number,
  comment: string,
) => {
  await fetch(`${config.API_ENDPOINT}/conversation/talk/comment`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      talkId,
      comment,
    }),
  });
};

export const sendFallbackContact = async (
  projectId: string,
  misunderstoodIntents: string[],
  comment: string,
  userMail: string,
  adminMail: string,
) => {
  await fetch(`${config.API_ENDPOINT}/conversation/fallback-contact`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      projectId,
      misunderstoodIntents,
      comment,
      userMail,
      adminMail,
    }),
  });
}

let sessionId: string;

function getSessionId() {
  if (!sessionId) {
    resetSessionId();
  }

  return sessionId;
}

export const getAlgoliaInfos = async (suggestionsEnabled:boolean): Promise<any> => {
  if (!suggestionsEnabled) {
    return {app: '', key: ''};
  }

  const response = await fetch(
    `${config.API_ENDPOINT}/algolia/infos`,
    {
      headers: {
        'content-type': 'application/json',
      },
    }
  );
  let {app, key}  = await response.json();

  return {app, key};
}

// function getIsIos() {
//   return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
// }

export function resetSessionId() {
  sessionId = btoa(`${Date.now()}`).replace(/\W/g, '');
}
