const MESSAGE_TYPE = 'lepetitmartin';

type OnMessageCallback = (payload: any) => void;

export interface MessagePayload {
  event: EVENTS;
  attributes?: any;
}

export function onMessage(callback: OnMessageCallback) {
  window.addEventListener('message', ({ data }) => {
    // If this is not an LPM-specific message, discard it.
    if (data.type !== MESSAGE_TYPE) {
      return;
    }

    callback(data.payload);
  });
}

export function sendMessage(
  payload: MessagePayload,
  iframe?: HTMLIFrameElement
) {
  const target = iframe ? iframe.contentWindow : window.parent;

  target &&
    target.postMessage(
      {
        type: MESSAGE_TYPE,
        payload,
      },
      '*'
    );
}

export enum EVENTS {
  CHATBOX_CLOSE = 'CHATBOX_CLOSE',
  BUTTON_TOGGLED_ON = 'BUTTON_TOGGLED_ON',
  BUTTON_TOGGLED_OFF = 'BUTTON_TOGGLED_OFF',
  WIDGET_READY = 'WIDGET_READY',
  IS_MOBILE = 'IS_MOBILE',
  IS_NOT_MOBILE = 'IS_NOT_MOBILE',
  BUBBLE_CLICKED = 'BUBBLE_CLICKED',
  BUBBLE_TOGGLED_OFF = 'BUBBLE_TOGGLED_OFF',
  READY_TO_BUILD = 'READY_TO_BUILD',
  IFRAME_READY = 'IFRAME_READY',
  TRACK_VISIT = 'TRACK_VISIT',
}
