import handleEventsFromIframes from './bus';
import { BASE_URL } from '../../config';
import { createIframe } from './utils';

/**
 * Creates and inject the button <iframe> tag directly into the DOM object.
 *
 * @param {string} qs query string
 */
const createButtonIframe = () =>
  createIframe(`${BASE_URL}/button/index.html`, `ButtonIframe`);

/**
 * Creates and inject the widget <iframe> tag directly into the DOM object.
 *
 * @param {string} qs query string
 */
const createWidgetIframe = () =>
  createIframe(`${BASE_URL}/widget/index.html`, `ChatboxIframe`);

/**
 * Creates and inject the bubble <iframe> tag directly into the DOM object.
 *
 * @param {string} qs query string
 */
const createBubbleIframe = () =>
  createIframe(`${BASE_URL}/bubble/index.html`, `BubbleIframe`);

/**
 * Creates all iframes and inject them into the DOM object.
 */
const bootstrapIframes = () => {
  const iframes = {
    button: createButtonIframe(),
    widget: createWidgetIframe(),
    bubble: createBubbleIframe(),
  };

  handleEventsFromIframes(iframes);
};

export default bootstrapIframes;
