export function createIframe(src: string, className: string) {
  const iframe = document.createElement('iframe');
  iframe.src = src;
  iframe.className = className;
  document.body.appendChild(iframe);

  return iframe;
}

export function getAgentIdFromDom(): string {
  const script = document.getElementById('le-petit-martin');

  const dataset = script.dataset;

  if (!dataset.agentId) {
    throw new Error(
      `Afin que le widget de votre chatbot fonctionne, vous devez lui passer ` +
        `le paramètre 'agentId'. Une injection typique du chatbot se ferai à ` +
        `l'aide de la balise script suivante: <script data-agent-id="le-petit-martin" src="https://static.lepetitmartin.com/iframes/bundle.js">`
    );
  }

  return dataset.agentId;
}

export function getFromBo(): boolean {
  const script = document.getElementById('le-petit-martin');

  return !!script.dataset.fromBo;
}
