// When locking the scroll on the whole page, the current scroll position is
// lost, which is why we're backing it up here.
let savedScroll: any = null;

export function disableScroll() {
  savedScroll = document.body.scrollTop;
  document.documentElement.classList.add('lepetitmartin-widget-open');
}

export function reenableScroll() {
  document.documentElement.classList.remove('lepetitmartin-widget-open');
  document.body.scrollTop = savedScroll;
}
