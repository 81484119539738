import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'navigator.sendBeacon';
import 'whatwg-fetch';

import '../common/polyfills/remove';
import { isIE, injectIECompatibleLoader } from '../common/ie';
import { fetchCustomization } from '../common/customization';
import { EVENTS, onMessage, sendMessage } from '../common/messaging';
import { injectCommonCss, injectCustomCss } from './styles';
import { getAgentIdFromDom, getFromBo } from './utils';
import {getAlgoliaInfos} from '../widget/lib/sdk';
import bootstrapIframes from './iframes';

const agentId = getAgentIdFromDom();
const fromBo = getFromBo();

let iframesReady: any = {
  widget: false,
  button: false,
  bubble: false,
};

/**
 * First, we ensure the browser is compatible with the latest widget, is not we inject the legacy version.
 *
 * Then, we inject the common CSS to style the incoming iframes and
 * we bootstrap the iframes (not the app) and wait for the "IFRAME_READY" events from them.
 *
 * Finally we fetch the widget customization, dispatch the config to the iframes
 * and start the app.
 */
(async () => {
  if (isIE()) {
    await injectIECompatibleLoader();

    return;
  }

  function isIframeLoaded() {
    return document.querySelectorAll('.ButtonIframe').length !== 0;
  }

  if (!isIframeLoaded()) {
    injectCommonCss();
    bootstrapIframes();
  }

  onMessage(async payload => {
    if (payload.event === EVENTS.IFRAME_READY) {
      iframesReady[payload.attributes] = true;

      // Check if each iframe is ready to build
      if (iframesReady.widget && iframesReady.bubble && iframesReady.button) {
        const customization = await fetchCustomization(agentId);
        const algoliaInfos = await getAlgoliaInfos(customization.suggestions_enabled);

        injectCustomCss(fromBo, customization.custom_css);

        sendMessage({
          event: EVENTS.READY_TO_BUILD,
          attributes: { agentId, customization, fromBo, algoliaInfos },
        });

        sendMessage({
          event: EVENTS.TRACK_VISIT,
          attributes: {
            hostName: window.location.hostname,
            pathName: window.location.pathname,
            clientName: customization.client_name
          }
        });
      }
    }
  });
})();